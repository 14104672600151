<template>
    <b-modal size="lg" id="retur-konsumen-modal" :no-close-on-backdrop="true">
        <b-row>
            <b-col sm="12" md="6">
                <b-form-group label="Pilih Konsumen">
                    <v-select :options="konsumens" label="text" v-model="form.konsumen"></v-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-form-group label="Pilih Faktur Penjualan">
            <v-select :options="penjualans" label="text" v-model="form.penjualan">
                <template #no-options>
                    <i class="d-block text-center">
                        Coba Pilih Konsumen yang lain
                    </i>
                </template>
            </v-select>
        </b-form-group>
        <b-form-group label="Tanggal Retur">
            <b-form-input type="date" v-model="form.tanggal"></b-form-input>
        </b-form-group>
        <b-form-group label="Keterangan (optional)">
            <b-form-textarea v-model="form.keterangan"></b-form-textarea>
        </b-form-group>
        <template #modal-footer>
            <section class="d-flex justify-content-end">
                <b-button variant="warning" @click.prevent="submit">{{ $route.params.id ? 'Simpan' : 'Selanjutnya' }}</b-button>
            </section>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BFormGroup, BFormTextarea, BRow, BCol, BFormInput, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
    props: {
        item: {
            default: () => ({
                id_penjualan: null,
                id_konsumen: null,
                id_sales: null,
                nama_konsumen: null,
                tanggal: null,
                id_gudang_m: null,
                keterangan: null,
                rincian: []
            })
        }
    },
    components: {
        BModal, BFormGroup, BFormTextarea, BFormInput,BButton, BRow, BCol,
        vSelect
    },
    watch: {
        "form.penjualan"(penjualan) {
            this.form.nama_konsumen = penjualan.nama_konsumen
            this.form.id_konsumen = penjualan.id_konsumen
            this.form.id_penjualan = penjualan.value
            this.form.rincian = penjualan.rincian
        },
        item(val) {
            if(val) {
                this.form = val
            }
            else {
                this.form = this.item
            }
        },
        "form.konsumen"(konsumen) {
            const params = {
                order: 'desc'
            }
            if(konsumen) {
                params.id_konsumen = konsumen.id
            }
            if(this.tanggalPenjualan) {
                params.tanggal = this.tanggalPenjualan
            }

            this.getPenjualan(params)
        }
    },
    methods: {
        async getKonsumen(params = null) {
            if(!params) {
                params = this.isSales ? { karyawan_id: this.user.karyawan.id } : { status: 3 }
            }
            this.konsumens = await this.$store.dispatch('konsumen/getData', params)
            this.konsumens.map(konsumen => {
                konsumen.value = konsumen.id
                konsumen.text = `${konsumen.nama_toko} (${konsumen.type_konsumen ? konsumen.type_konsumen.nama : konsumen.nama_owner})`
            })
        },
        submit() {
            if(!this.form.id_penjualan || !this.form.tanggal) {
                this.displayError({
                    message: 'Harap Isi Form Retur'
                })
                return false
            }
            
            if(!this.form.id_konsumen) {
                this.displayError({
                    message: 'Data konsumen di penjualan yang dipilih tidak ada'
                })
                return false
            }

            this.form.id_sales = this.user.karyawan.id

            // get gudang mobil from sales
            const id_gudang_m = this.myGudang.id
            this.$emit('submit', {
                id_penjualan: this.form.id_penjualan,
                id_konsumen: this.form.id_konsumen,
                id_sales: this.form.id_sales,
                id_gudang_m,
                tanggal: this.form.tanggal,
                keterangan: this.form.keterangan,
                rincian: this.form.rincian
            })
        },
        setNormalDate(date) {
            const dateArr = date.split('-')
            return dateArr.reverse().join('-')
        },
        async getPenjualan(params) {
            if(this.isSales) {
                params.id_sales = this.user.karyawan.id
            }
            this.penjualans = []
            const penjualans = await this.$store.dispatch('penjualan/getData', params)
            penjualans.map(penjualan => {
                const fakturName = `${penjualan.no_kwitansi} / ${this.setNormalDate(penjualan.tanggal)}`
                this.penjualans.push({
                    value: penjualan.id,
                    text: fakturName,
                    nama_konsumen: penjualan.konsumen ? penjualan.konsumen.nama_toko : '-',
                    id_konsumen: penjualan.konsumen  ? penjualan.konsumen.id : null,
                    rincian: penjualan.rincian
                })
            })
        }
    },
    data: () => ({
        konsumens: [],
        tanggalPenjualan: null,
        penjualans: [],
        form: {
            id_penjualan: null,
            id_konsumen: null,
            konsumen: null,
            penjualan: null,
            id_sales: null,
            nama_konsumen: null,
            tanggal: null,
            id_gudang_m: null,
            keterangan: null,
            rincian: []
        }
    }),
    created() {
        this.getKonsumen()
        // this.getPenjualan()
        this.form = this.item
        this.form.tanggal = this.getCurrentDate()
    }
}
</script>